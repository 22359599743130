export const gridOptions = {
    columnDefs: [
        {
            headerName: 'ID',
            field: 'id',
            editable: false,
        },
        {
            headerName: 'Bhome ID',
            field: 'bhome_id',
            cellRenderer: 'link',
            cellRendererParams: {
                url: '/bhomes',
            },
        },
        {
            headerName: 'Code',
            field: 'code',
            editable: false,
        },
        {
            headerName: 'Created at',
            field: 'created_at',
            editable: false,
            valueGetter: params => new Date(params.data.created_at).toLocaleString(),
        },
    ],
};
